import { Button } from "components";
import { ReplyTypeLabels } from "utils/constants";
import { ReplyType } from "utils/enum";
import { BaseReplySection } from "../base-reply-section";
import { ActionButtonsSectionProps } from "./types";

export default function ActionButtonsSection({
	mainButtonType = "submit",
	mainButtonLabel = ReplyTypeLabels[ReplyType.BUDGET],
	mainButtonDisabled = false,
	onClickMainButton,
	secondaryButtonLabel = ReplyTypeLabels[ReplyType.MESSAGE],
	onClickSecondaryButton,
	onClickRefuseButton
}: ActionButtonsSectionProps) {
	return (
		<BaseReplySection>
			<Button
				type={mainButtonType}
				variant={mainButtonType === "submit" ? "primary" : "outline"}
				disabled={mainButtonDisabled}
				onClick={onClickMainButton}
			>
				{mainButtonLabel}
			</Button>
			<Button type="button" variant="outline" onClick={onClickSecondaryButton}>
				{secondaryButtonLabel}
			</Button>
			<Button
				type="button"
				variant="warning-text"
				onClick={onClickRefuseButton}
			>
				Recusar Orçamento
			</Button>
		</BaseReplySection>
	);
}
