import { baseConfig } from "config";
import { useNavigate } from "react-router-dom";
import { store } from "store";
import { setFirstAcess } from "store/features/flags/slice";
import { resetRegistration } from "store/features/registration/slice";
import {
	setAccessToken,
	setLoggedUser,
	setLogout,
	setTokens
} from "store/features/tokens/slice";
import {
	RetrieveSelfData,
	SelfRegisterTokens
} from "store/features/tokens/types";
import { Requester } from "./requester";

export const getAccessToken = async () => {
	const { access, refresh } = store.getState().tokens;
	// logout
	if (!access) {
		if (refresh) {
			renewAccessToken();
		} else {
			store.dispatch(setLogout());
			store.dispatch(resetRegistration());
			const navigate = useNavigate();
			navigate("/login");
		}
	}
	return access;
};

export const renewAccessToken = async () => {
	const { refresh } = store.getState().tokens;
	const response = await fetch(`${baseConfig.baseURL}/auth/token/refresh/`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			refresh
		})
	});
	const parsedResponse = await response.json();
	store.dispatch(setAccessToken(parsedResponse.access));
	return parsedResponse.access;
};

export const resetAppPassword = async (
	token: string,
	email: string,
	password: string,
	passwordConfirmation: string
) => {
	return Requester.put({
		url: `${baseConfig.baseURL}/auth/change-password/${token}/`,
		body: {
			email,
			new_password: password,
			new_password_confirm: passwordConfirmation
		},
		success: (result) => {
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};

export const createPartnerAccount = async (
	email: string,
	password: string,
	passwordConfirmation: string
) => {
	return Requester.post({
		url: `${baseConfig.baseURL}/auth/register/`,
		body: {
			email,
			password,
			password_confirm: passwordConfirmation
		},
		success: (result): SelfRegisterTokens => {
			store.dispatch(setTokens(result.access_token));
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};

export const partnerLogin = async (email: string, password: string) => {
	return Requester.post({
		url: `${baseConfig.baseURL}/auth/token/partner/`,
		body: {
			email,
			password
		},
		success: (result): SelfRegisterTokens => {
			store.dispatch(setTokens(result.access_token));
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};

export const getPartnerUuid = async () => {
	const access_token = await getAccessToken();
	return Requester.get({
		url: `${baseConfig.baseURL}/auth/user/retrieve-self`,
		token: access_token,
		tokenType: "Bearer",
		success: (result): RetrieveSelfData => {
			store.dispatch(setFirstAcess(false));
			store.dispatch(setLoggedUser(result));
			return result.service_provider_id;
		},
		error: (error) => {
			throw error;
		}
	});
};

export const requestPasswordReset = async (email: string) => {
	return Requester.post({
		url: `${baseConfig.baseURL}/auth/trigger-reset-password/partner/`,
		body: {
			email
		},
		success: (result) => {
			return result;
		},
		error: (error) => {
			throw error;
		}
	});
};

export const logout = async () => {
	store.dispatch(setLogout());
	store.dispatch(resetRegistration());
};
