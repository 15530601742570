import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "store";

interface FallbackRouteProps {
	route: string;
	condition: boolean;
}

function PrivateRoute({
	fallback,
	publicFallback
}: {
	fallback: FallbackRouteProps;
	publicFallback: FallbackRouteProps;
}) {
	if (publicFallback.condition) return <Navigate to={publicFallback.route} />;

	return fallback.condition ? <Navigate to={fallback.route} /> : <Outlet />;
}

function CompleteRegistrationRoute({
	fallbackRoute,
	publicFallbackRoute
}: {
	fallbackRoute: string;
	publicFallbackRoute: string;
}) {
	const { logged_user } = useSelector((state: RootState) => state.tokens);
	const { isFirstAccess } = useSelector((state: RootState) => state.flags);

	return PrivateRoute({
		fallback: { route: fallbackRoute, condition: isFirstAccess },
		publicFallback: { route: publicFallbackRoute, condition: !logged_user }
	});
}

function IncompleteRegistrationRoute({
	fallbackRoute,
	publicFallbackRoute
}: {
	fallbackRoute: string;
	publicFallbackRoute: string;
}) {
	const { refresh, access } = useSelector((state: RootState) => state.tokens);
	const { isFirstAccess } = useSelector((state: RootState) => state.flags);

	return PrivateRoute({
		fallback: { route: fallbackRoute, condition: !isFirstAccess },
		publicFallback: {
			route: publicFallbackRoute,
			condition: !(access || refresh)
		}
	});
}

function PublicRouteOnly({ fallbackRoute }: { fallbackRoute: string }) {
	const { access, refresh } = useSelector((state: RootState) => state.tokens);

	return !(access || refresh) ? <Outlet /> : <Navigate to={fallbackRoute} />;
}

export {
	CompleteRegistrationRoute,
	IncompleteRegistrationRoute,
	PublicRouteOnly
};
