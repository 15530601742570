import { IcoCalendar } from "assets/icons";
import { Checkbox, Input, ShallowInput } from "components";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { getFormattedHoursAndMinutes } from "utils/functions";
import { BaseReplySection, InlineContainer } from "../base-reply-section";

export default function VisitSection({
	formik,
	setNightVisit
}: {
	formik: any;
	setNightVisit: (arg0: boolean) => void;
}) {
	const [visitAmount, setVisitAmount] = useState<string>(
		formik.values.visitAmount?.toString().replace(".", ",") || ""
	);
	const minDate = new Date();
	const [visitHour, setVisitHour] = useState<{ value: string; error: string }>({
		value: "",
		error: ""
	});

	useEffect(() => {
		if (!formik.values.visitDatetime) return;
		updateTimes(formik.values.visitDatetime);
	}, [formik.values.visitDatetime]);

	const updateTimes = (newDate: Date) => {
		setNightVisit(newDate.getHours() >= 22 || newDate.getHours() < 6);
		const { hours, minutes } = getFormattedHoursAndMinutes(newDate);
		setVisitHour({
			...visitHour,
			value: `${hours}:${minutes}`
		});
	};

	const handleDateChange = (date: Date) => {
		if (!date) return;
		const newDate = date;
		const now = new Date();
		if (!formik.values.visitDatetime || date < now) {
			newDate.setHours(now.getHours(), now.getMinutes() + 30, 0, 0);
		}
		formik.setFieldValue("visitDatetime", newDate);
	};

	const handleAmountChange = (e: any) => {
		const { value } = e.target;
		if (!value) {
			formik.setFieldValue("visitAmount", "");
			setVisitAmount("");
			return;
		}
		const numberValue = Number(value.replace(/[^0-9]/g, ""));
		const currencyValue = (numberValue / 100).toFixed(2);
		const formattedValue = currencyValue
			.replace(".", ",")
			.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		setVisitAmount(formattedValue);

		formik.setFieldValue("visitAmount", currencyValue);
	};

	const handleTimeChange = (e: any) => {
		const { value } = e.target;
		if (!value) {
			setVisitHour({ value: "", error: "Informe uma hora válida" });
			return;
		}

		const numberValue = Number(value.replace(/[^0-9]/g, ""));
		const formattedValue = numberValue.toString().padStart(4, "0");
		const hours = formattedValue.slice(0, 2);
		const minutes = formattedValue.slice(2);
		const newDate = new Date(formik.values.visitDatetime);
		newDate.setHours(Number(hours), Number(minutes), 0, 0);

		if (newDate <= new Date()) {
			setVisitHour({
				value,
				error: "Informe uma hora futura"
			});
			return;
		}

		setNightVisit(Number(hours) >= 22 || Number(hours) < 6);
		setVisitHour({
			value,
			error: ""
		});

		formik.setFieldValue("visitDatetime", newDate);
	};

	return (
		<BaseReplySection
			title="Disponibilidade e Valores"
			description={[
				"Selecione a data e horário mais próximos em que você estiver disponível e o valor de sua visita ou serviço."
			]}
		>
			<InlineContainer>
				<ReactDatePicker
					selected={
						(formik.values.visitDatetime &&
							new Date(formik.values.visitDatetime)) ||
						null
					}
					onChange={handleDateChange}
					onBlur={formik.handleBlur}
					minDate={minDate}
					wrapperClassName="w-full"
					dateFormat="dd/MM/yyyy"
					placeholderText="00/00/0000"
					customInput={
						<ShallowInput
							label="DATA*"
							id="visitDate"
							name="visitDate"
							placeholder="00/00/0000"
							error={
								formik.touched.visitDatetime && formik.errors.visitDatetime
							}
							trailing={<IcoCalendar />}
						/>
					}
				/>
				<Input
					id="visitHour"
					name="visitHour"
					label="HORA*"
					value={visitHour.value}
					onChange={handleTimeChange}
					onBlur={formik.handleBlur}
					error={visitHour.error}
					errorMessage={visitHour.error}
					disabled={!formik.values.visitDatetime}
					type="time"
				/>
			</InlineContainer>
			<Input
				id="visitAmount"
				name="visitAmount"
				placeholder=" 0,00"
				label="VALOR*"
				inputLeading={<span>R$&nbsp;</span>}
				value={visitAmount}
				onChange={handleAmountChange}
				onBlur={formik.handleBlur}
				error={formik.touched.visitAmount && formik.errors.visitAmount}
				errorMessage={formik.errors.visitAmount}
			/>
			<Checkbox
				id="isVisitOnly"
				name="isVisitOnly"
				label={<span>Valor apenas da visita</span>}
				value="isVisitOnly"
				checked={formik.values.isVisitOnly}
				onChange={formik.handleChange}
			/>
		</BaseReplySection>
	);
}
