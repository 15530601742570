import { Loading } from "components";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "services/auth";
import { getProviderBudgetRequests } from "services/budget-request";
import { BudgetRequestProvider } from "store/features/budget-request/types";
import DashboardEmptyContent from "./EmptyContent/EmptyContent";
import DashboardRequestList from "./RequestList/RequestList";

import styles from "./styles.module.scss";

export default function ProviderDashboard(): JSX.Element {
	const [unreadRequests, setUnreadRequests] = useState<BudgetRequestProvider[]>(
		[]
	);
	const [readRequests, setReadRequests] = useState<BudgetRequestProvider[]>([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		setUnreadRequests([]);
		setReadRequests([]);
		setLoading(true);
		getProviderBudgetRequests()
			.then((response) => {
				const budgets = response ? (response as BudgetRequestProvider[]) : [];
				const newUnreadRequests: BudgetRequestProvider[] = [];
				const newReadRequests: BudgetRequestProvider[] = [];

				budgets.forEach((budget) => {
					if (budget.has_unread_messages) {
						newUnreadRequests.push(budget);
					} else {
						newReadRequests.push(budget);
					}
				});
				setUnreadRequests(newUnreadRequests);
				setReadRequests(newReadRequests);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				if (error.code === 401) {
					logout();
					navigate("/login");
				} else {
					throw error;
				}
			});
	}, []);

	return loading ? (
		<main className={styles.noContentWrapper}>
			<Loading size="large" />
		</main>
	) : unreadRequests.length === 0 && readRequests.length === 0 ? (
		<main className={styles.noContentWrapper}>
			<DashboardEmptyContent />
		</main>
	) : (
		<main className={styles.dashboardWrapper}>
			{unreadRequests.length > 0 && (
				<DashboardRequestList title="Não lidas" items={unreadRequests} />
			)}
			{readRequests.length > 0 && (
				<DashboardRequestList title="Todas" items={readRequests} />
			)}
		</main>
	);
}
