import { Route, Routes } from "react-router-dom";

import {
	FeedbackPage,
	OfferedServicesPage,
	PersonalInformationPage,
	SuperPerfilPage
} from "pages";

export default function RegistrationRoutes(): JSX.Element {
	return (
		<Routes>
			<Route
				path="informacoes-pessoais"
				element={<PersonalInformationPage />}
			/>
			<Route path="tipos-de-servicos" element={<OfferedServicesPage />} />
			<Route path="super-perfil" element={<SuperPerfilPage />} />
			<Route path="feedback" element={<FeedbackPage />} />
		</Routes>
	);
}
