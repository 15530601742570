export enum BudgetStatus {
	OPEN = 1,
	SENT = 2,
	ACCEPTED = 3,
	REFUSED = 4,
	IN_PROGRESS = 5,
	FINALIZED = 6,
	EXPIRED = 7,
	DIRECT_CONTACT = 8
}

export enum MessageType {
	DEFAULT = "default_message",
	BUDGET_APPROVED_DETAILS = "budget_approved_details_message",
	BUDGET_APPROVED = "budget_approved_message",
	BUDGET_DECLINED = "budget_declined_message",
	DIRECT_CONTACT = "direct_contact",
	BUDGET_DETAILS = "budget_details"
}

export enum MessageSourceType {
	FRANCHISEE = 1,
	SERVICE_PROVIDER = 2,
	TRIG_UP = 3
}

export enum ReplyType {
	BUDGET = 1,
	MESSAGE = 2
}
