import { useNavigate, useParams } from "react-router-dom";

import { Badge, ErrorState } from "components";

import { IcoError } from "assets/icons";
import ChatMessage from "components/ChatMessage";
import ChatVisitInformationMessage from "components/ChatMessage/ChatVisitInformationMessage";
import MessageBudgetAccepted from "components/ChatMessage/MessageBudgetAccepted";
import { LogoHeader } from "components/Header";
import { Fragment, useEffect, useState } from "react";
import { getBudgetRequest } from "services/budget-request";
import {
	BudgetRequestState,
	Message
} from "store/features/budget-request/types";
import { BudgetStatus, MessageSourceType, MessageType } from "utils/enum";
import {
	formatChatDate,
	formatMessageDate,
	isTheSameDay,
	sortArrayByTimestamp
} from "utils/functions";

import BudgetRequestReply from "./budget-request-reply";
import styles from "./styles.module.scss";

export default function BudgetRequestPage() {
	const { budgetRequestId } = useParams();

	const [budgetRequestData, setBudgetRequestData] =
		useState<BudgetRequestState>();

	const [messageList, setMessageList] = useState<Message[]>([]);
	const [approvedTimestamp, setApprovedTimestamp] = useState("");

	const [errorRetrievingData, setErrorRetrievingData] =
		useState<boolean>(false);
	const navigate = useNavigate();

	const setBudgetAcceptedTimestamp = (messages: Message[] = []) => {
		const accepted = messages.find(
			(message) => message.message_type === MessageType.BUDGET_APPROVED
		);
		setApprovedTimestamp(formatMessageDate(accepted?.created_at || ""));
	};

	useEffect(() => {
		const fetchData = async () => {
			await getBudgetRequest(budgetRequestId)
				.then((resp) => {
					if (resp) {
						const data = resp as BudgetRequestState;
						setBudgetRequestData(data);
						setMessageList(sortArrayByTimestamp(data.messages));

						switch (data.status) {
							case BudgetStatus.REFUSED:
							case BudgetStatus.EXPIRED:
								navigate(`/budget-request/${budgetRequestId}/expired`);
								break;
							case BudgetStatus.ACCEPTED:
							case BudgetStatus.FINALIZED:
								setBudgetAcceptedTimestamp(data.messages);
								break;
							case BudgetStatus.OPEN:
							case BudgetStatus.SENT:
							case BudgetStatus.IN_PROGRESS:
							default:
						}
					}
				})
				.catch((err) => {
					if (err.body?.[0].toLowerCase() === "expired link") {
						navigate(`/budget-request/${budgetRequestId}/expired`);
						return;
					}
					setErrorRetrievingData(true);
				});
		};
		fetchData();
	}, []);

	return !errorRetrievingData ? (
		<>
			<LogoHeader title="Pedido de Orçamento" />
			<div className={styles.centerTag}>
				<Badge
					type="order"
					label={`ORÇAMENTO ${
						budgetRequestId ? budgetRequestId.slice(-6) : ""
					}`}
				/>
			</div>
			<div className={styles.centerTag}>
				<Badge
					type="timestamp"
					label={
						budgetRequestData?.created_at
							? formatChatDate(budgetRequestData?.created_at)
							: ""
					}
				/>
			</div>
			<ChatMessage
				service="Envie sua proposta"
				message="Não comunique dados de contato, apenas informações sobre o serviço"
				messageSource={MessageSourceType.TRIG_UP}
			/>

			{messageList.map(
				(item, index, array) =>
					item.message_source !== MessageSourceType.TRIG_UP && (
						<Fragment key={`message-${index}`}>
							{index > 0 &&
								!isTheSameDay(item.created_at, array[index - 1].created_at) && (
									<div className={styles.centerTag}>
										<Badge
											type="timestamp"
											label={formatChatDate(item.created_at)}
										/>
									</div>
								)}
							{item.message_type === "budget_details" && item.extra_info ? (
								<ChatVisitInformationMessage
									message={item.message}
									timestamp={formatMessageDate(item.created_at)}
									profilePic={budgetRequestData?.service_provider.image}
									extraInfo={item.extra_info}
								/>
							) : (
								<ChatMessage
									message={item.message}
									timestamp={formatMessageDate(item.created_at)}
									messageSource={item.message_source}
									profilePic={budgetRequestData?.service_provider.image}
									messageSender={budgetRequestData?.franchisee?.franchisor}
									key={item.id}
								/>
							)}
						</Fragment>
					)
			)}
			{budgetRequestData?.status === BudgetStatus.ACCEPTED ||
			budgetRequestData?.status === BudgetStatus.FINALIZED ? (
				<MessageBudgetAccepted
					acceptedTimestamp={approvedTimestamp}
					franchisee={budgetRequestData?.franchisee}
				/>
			) : (
				<BudgetRequestReply
					budgetRequestId={budgetRequestId ?? ""}
					setErrorRetrievingData={setErrorRetrievingData}
					profilePic={budgetRequestData?.service_provider.image}
					firstBudgetMessage={messageList.find(
						(msg) =>
							msg.message_source === MessageSourceType.SERVICE_PROVIDER &&
							msg.message_type === MessageType.BUDGET_DETAILS
					)}
				/>
			)}
		</>
	) : (
		<ErrorState
			title="Houve um erro ao carregar seus dados"
			message="Entre em contato com nosso atendimento."
			icon={<IcoError />}
		/>
	);
}
