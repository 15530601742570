import { Input } from "components";
import { ReplyTypeLabels } from "utils/constants";
import { ReplyType } from "utils/enum";
import ActionButtonsSection from "../action-buttons-section";
import { BaseReplySection } from "../base-reply-section";

export default function NightHoursSection({
	formik,
	onClickChangeType,
	handleToggleModal
}: {
	formik: any;
	onClickChangeType: () => void;
	handleToggleModal: () => void;
}) {
	const handleDocumentChange = (e: any) => {
		const { value } = e.target;
		if (!value) {
			formik.setFieldValue("providerFiscalDocument", "");
			return;
		}
		let formattedValue = value.replace(/[^0-9]/g, "");
		formattedValue = formattedValue.slice(0, 11);
		if (formattedValue.length >= 3) {
			formattedValue = `${formattedValue.slice(0, 3)}.${formattedValue.slice(
				3
			)}`;
		}
		if (formattedValue.length >= 7) {
			formattedValue = `${formattedValue.slice(0, 7)}.${formattedValue.slice(
				7
			)}`;
		}
		if (formattedValue.length >= 11) {
			formattedValue = `${formattedValue.slice(0, 11)}-${formattedValue.slice(
				11
			)}`;
		}
		formik.setFieldValue("providerFiscalDocument", formattedValue);
	};

	return (
		<BaseReplySection
			title="Horário Noturno"
			description={[
				"A seleção de um horário noturno exige a identificação do prestador que irá realizar o serviço."
			]}
		>
			<Input
				id="providerName"
				name="providerName"
				placeholder=""
				label="NOME DO(A) PRESTADOR(A)*"
				value={formik.values.providerName || ""}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={formik.touched.providerName && formik.errors.providerName}
				errorMessage={formik.errors.providerName}
				type="text"
			/>
			<Input
				id="providerFiscalDocument"
				name="providerFiscalDocument"
				placeholder="000.000.000-00"
				label="CPF DO(A) PRESTADOR(A)*"
				value={formik.values.providerFiscalDocument || ""}
				onChange={handleDocumentChange}
				onBlur={formik.handleBlur}
				error={
					formik.touched.providerFiscalDocument &&
					formik.errors.providerFiscalDocument
				}
				errorMessage={formik.errors.providerFiscalDocument}
			/>
			<ActionButtonsSection
				mainButtonType="submit"
				mainButtonLabel="Enviar Proposta"
				mainButtonDisabled={!formik.isValid}
				onClickMainButton={formik.handleSubmit}
				secondaryButtonLabel={ReplyTypeLabels[ReplyType.MESSAGE]}
				onClickSecondaryButton={onClickChangeType}
				onClickRefuseButton={handleToggleModal}
			/>
		</BaseReplySection>
	);
}
