import styles from "./styles.module.scss";
import { BaseReplySectionProps, InlineContainerProps } from "./types";

export function InlineContainer({ children }: InlineContainerProps) {
	return <div className={styles.inlineContainer}>{children}</div>;
}

export function BaseReplySection({
	title,
	description,
	children
}: BaseReplySectionProps) {
	return (
		<section className={styles.replySection}>
			{title && <h3 className={styles.sectionTitle}>{title}</h3>}
			{description?.map((item, index) => (
				<span key={index} className={styles.sectionDescription}>
					{item}
				</span>
			))}
			{children}
		</section>
	);
}
