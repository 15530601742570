import { Textarea } from "components";
import { ReplyTypeLabels } from "utils/constants";
import { ReplyType } from "utils/enum";
import { BudgetRequestReplyModel } from "../../types";
import ActionButtonsSection from "../action-buttons-section";
import { BaseReplySection } from "../base-reply-section";
import { CommentSectionProps } from "./types";

export default function CommentSection({
	title,
	description,
	formik,
	handleToggleModal,
	replyType,
	showButtons = true,
	onClickChangeType
}: CommentSectionProps) {
	return (
		<BaseReplySection title={title} description={description}>
			<Textarea
				id="message"
				name="message"
				placeholder="Adicione detalhes sobre o serviço, como requisitos necessários para o atendimento"
				label={`COMENTÁRIOS${
					formik.values.replyType === ReplyType.BUDGET ? "" : "*"
				}`}
				required={formik.values.replyType === ReplyType.MESSAGE}
				error={formik.touched.message && formik.errors.message}
				value={formik.values.message}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				errorMessage={formik.errors.message}
				maxLength={BudgetRequestReplyModel.messageMaxLength}
				showCount
				rows={5}
			/>
			{showButtons && (
				<ActionButtonsSection
					mainButtonType="submit"
					mainButtonLabel={`Enviar ${
						replyType === ReplyType.BUDGET ? "Proposta" : "Mensagem"
					}`}
					mainButtonDisabled={!formik.isValid}
					onClickMainButton={formik.handleSubmit}
					secondaryButtonLabel={
						ReplyTypeLabels[
							replyType === ReplyType.BUDGET
								? ReplyType.MESSAGE
								: ReplyType.BUDGET
						]
					}
					onClickSecondaryButton={onClickChangeType}
					onClickRefuseButton={handleToggleModal}
				/>
			)}
		</BaseReplySection>
	);
}
