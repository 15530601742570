import { useFormik } from "formik";

import { ModalBudgetRequestRefuseConfirmation } from "components";

import { MessageReply } from "components/ChatMessage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { rejectBudgetRequest, sendMessage } from "services/budget-request";
import { MessageType, ReplyType } from "utils/enum";
import {
	CommentSection,
	NightHoursSection,
	SelectReplyTypeSection,
	VisitSection
} from "./reply-sections";
import {
	BudgetRequestReplyModel,
	BudgetRequestReplyModelProps,
	BudgetRequestReplyProps
} from "./types";

export default function BudgetRequestReply({
	profilePic,
	budgetRequestId,
	firstBudgetMessage,
	setErrorRetrievingData
}: BudgetRequestReplyProps) {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [initialValues, setInitialValues] =
		useState<BudgetRequestReplyModelProps>(
			BudgetRequestReplyModel.initialValues
		);
	const [nightVisit, setNightVisit] = useState<boolean>(false);
	const [replyType, setReplyType] = useState<ReplyType | null>(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (
			!firstBudgetMessage ||
			!firstBudgetMessage.extra_info ||
			firstBudgetMessage.message_type !== MessageType.BUDGET_DETAILS
		)
			return;

		const newValues: BudgetRequestReplyModelProps = {
			message: "",
			replyType: 0
		};
		newValues.visitDatetime = new Date(
			firstBudgetMessage.extra_info.visit_datetime
		);
		newValues.visitAmount = firstBudgetMessage.extra_info.amount;
		newValues.isVisitOnly = firstBudgetMessage.extra_info.is_visit_only;
		newValues.providerName = firstBudgetMessage.extra_info.provider_name || "";
		newValues.providerFiscalDocument =
			firstBudgetMessage.extra_info?.provider_fiscal_document || "";

		setNightVisit(
			newValues.visitDatetime.getHours() >= 22 ||
				newValues.visitDatetime.getHours() < 6
		);
		setInitialValues(newValues);
	}, [firstBudgetMessage]);

	const updateReplyType = (type: number) => {
		setReplyType(type);
		formik.setFieldValue("replyType", type);
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnMount: true,
		validationSchema: BudgetRequestReplyModel.validationSchema,
		onSubmit: async (values) => {
			const parsedValues: { message: string; visit_information?: any } = {
				message: values.message
			};
			if (
				values.replyType === ReplyType.BUDGET &&
				(values.visitDatetime?.getTime() !==
					initialValues.visitDatetime?.getTime() ||
					values.visitAmount !== initialValues.visitAmount ||
					values.isVisitOnly !== initialValues.isVisitOnly ||
					values.providerName !== initialValues.providerName ||
					values.providerFiscalDocument !==
						initialValues.providerFiscalDocument)
			) {
				parsedValues.visit_information = {
					visit_datetime: values.visitDatetime,
					amount: Number(values.visitAmount ?? 0).toFixed(2),
					is_visit_only: values.isVisitOnly
				};
				if (nightVisit) {
					parsedValues.visit_information.provider_name = values.providerName;
					parsedValues.visit_information.provider_fiscal_document =
						values.providerFiscalDocument;
				}
			}
			await sendMessage(parsedValues, budgetRequestId)
				.then(() => {
					navigate(`/budget-request/${budgetRequestId}/sent`);
				})
				.catch(() => {
					setErrorRetrievingData(true);
				});
		}
	});

	const handleToggleModal = () => {
		setShowModal(!showModal);
	};

	const handleRefuseBudgetRequest = async () => {
		await rejectBudgetRequest(formik.values.message, budgetRequestId)
			.then(() => {
				navigate(`/budget-request/${budgetRequestId}/rejected`);
			})
			.catch(() => {
				setErrorRetrievingData(true);
			});
	};

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				{!replyType && (
					<MessageReply profilePic={profilePic}>
						<SelectReplyTypeSection
							onBudgetClick={() => updateReplyType(ReplyType.BUDGET)}
							onMessageClick={() => updateReplyType(ReplyType.MESSAGE)}
							onRefuseClick={handleToggleModal}
						/>
					</MessageReply>
				)}
				{replyType === ReplyType.MESSAGE && (
					<MessageReply profilePic={profilePic}>
						<CommentSection
							title="Enviar mensagem ao cliente"
							description={[
								"Tire suas dúvidas e peça mais informações antes de preencher o orçamento"
							]}
							formik={formik}
							replyType={replyType}
							handleToggleModal={handleToggleModal}
							onClickChangeType={() => updateReplyType(ReplyType.BUDGET)}
						/>
					</MessageReply>
				)}
				{replyType === ReplyType.BUDGET && (
					<MessageReply profilePic={profilePic}>
						<>
							<VisitSection formik={formik} setNightVisit={setNightVisit} />
							<CommentSection
								formik={formik}
								replyType={replyType}
								showButtons={!nightVisit}
								handleToggleModal={handleToggleModal}
								onClickChangeType={() => updateReplyType(ReplyType.MESSAGE)}
							/>
						</>
						{nightVisit && (
							<NightHoursSection
								formik={formik}
								handleToggleModal={handleToggleModal}
								onClickChangeType={() => updateReplyType(ReplyType.MESSAGE)}
							/>
						)}
					</MessageReply>
				)}
			</form>
			<ModalBudgetRequestRefuseConfirmation
				open={showModal}
				onClose={handleToggleModal}
				onAgree={handleRefuseBudgetRequest}
			/>
		</>
	);
}
