import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
	BudgetRequestPage,
	LandingPage,
	LoginPage,
	PrivacyPolicyPage,
	ProviderDashboard,
	ResetPasswordPage,
	SignInPage
} from "pages";

import { ProviderLayout, SingleLayout, TwoColumnsLayout } from "layouts";
import BudgetRequestExpiredPage from "pages/budget-request/budget-request-expired";
import BudgetRequestMessageSentPage from "pages/budget-request/budget-request-message-sent";
import BudgetRequestRefusedPage from "pages/budget-request/budget-request-refused";
import PasswordRecoveryPage from "pages/password-recovery";
import SuccessPasswordResetPage from "pages/password-recovery/success";
import {
	CompleteRegistrationRoute,
	IncompleteRegistrationRoute,
	PublicRouteOnly
} from "./private-route";
import RegistrationRoutes from "./registration";

function Routers() {
	const { pathname } = useLocation();

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		}, 100);
	}, [pathname]);

	return (
		<Routes>
			<Route path="/" element={<LandingPage />} />
			<Route path="/reset-password/" element={<ResetPasswordPage />} />
			<Route
				path="/password-recovery/:token"
				element={<PasswordRecoveryPage />}
			/>
			<Route path="/recovery-success" element={<SuccessPasswordResetPage />} />
			<Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

			<Route element={<PublicRouteOnly fallbackRoute="/dashboard" />}>
				<Route path="" element={<TwoColumnsLayout />}>
					<Route path="/signin" element={<SignInPage />} />
					<Route path="/login" element={<LoginPage />} />
				</Route>
			</Route>

			<Route
				element={
					<IncompleteRegistrationRoute
						fallbackRoute="/dashboard"
						publicFallbackRoute="/login"
					/>
				}
			>
				<Route path="/cadastro" element={<SingleLayout />}>
					<Route path="/cadastro/*" element={<RegistrationRoutes />} />
				</Route>
			</Route>

			<Route
				element={
					<CompleteRegistrationRoute
						fallbackRoute="/cadastro/informacoes-pessoais"
						publicFallbackRoute="/login"
					/>
				}
			>
				<Route path="" element={<ProviderLayout />}>
					<Route path="/dashboard" element={<ProviderDashboard />} />
					<Route path="/dashboard/cadastro" element={<SingleLayout />}>
						<Route
							path="/dashboard/cadastro/*"
							element={<RegistrationRoutes />}
						/>
					</Route>

					<Route path="/dashboard/budget-request" element={<SingleLayout />}>
						<Route path=":budgetRequestId" element={<BudgetRequestPage />} />
						<Route
							path=":budgetRequestId/sent"
							element={<BudgetRequestMessageSentPage />}
						/>
						<Route
							path=":budgetRequestId/rejected"
							element={<BudgetRequestRefusedPage />}
						/>
						<Route
							path=":budgetRequestId/expired"
							element={<BudgetRequestExpiredPage />}
						/>
					</Route>
				</Route>
			</Route>

			<Route path="/budget-request" element={<SingleLayout />}>
				<Route path=":budgetRequestId" element={<BudgetRequestPage />} />
				<Route
					path=":budgetRequestId/sent"
					element={<BudgetRequestMessageSentPage />}
				/>
				<Route
					path=":budgetRequestId/rejected"
					element={<BudgetRequestRefusedPage />}
				/>
				<Route
					path=":budgetRequestId/expired"
					element={<BudgetRequestExpiredPage />}
				/>
			</Route>

			<Route path="/*" element={<Navigate to="/" />} />
		</Routes>
	);
}

export default Routers;
