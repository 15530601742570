import * as Yup from "yup";

import { Message } from "store/features/budget-request/types";

export interface BudgetRequestReplyProps {
	profilePic?: string;
	budgetRequestId: string;
	firstBudgetMessage?: Message;
	setErrorRetrievingData: (value: boolean) => void;
}

const MESSAGE_MAX_LENGTH = 600;

export interface BudgetRequestReplyModelProps {
	message: string;
	replyType: number;
	visitDatetime?: Date;
	visitAmount?: number;
	isVisitOnly?: boolean;
	providerName?: string;
	providerFiscalDocument?: string;
}

export class BudgetRequestReplyModel {
	message?: string;
	replyType: number;
	visitDatetime?: Date;
	visitAmount?: number;
	isVisitOnly?: boolean;
	providerName?: string;
	providerFiscalDocument?: string;

	constructor(data: BudgetRequestReplyModelProps) {
		this.message = data.message;
		this.replyType = data.replyType;
		this.visitDatetime = data.visitDatetime;
		this.visitAmount = data.visitAmount;
		this.isVisitOnly = data.isVisitOnly;
		this.providerName = data.providerName;
		this.providerFiscalDocument = data.providerFiscalDocument;
	}

	static createInitial() {
		return new BudgetRequestReplyModel({
			message: "",
			replyType: 0
		});
	}

	static get messageMaxLength() {
		return MESSAGE_MAX_LENGTH;
	}

	static get initialValues() {
		return {
			message: "",
			replyType: 0,
			visitDatetime: undefined,
			visitAmount: undefined,
			isVisitOnly: false,
			providerName: "",
			providerFiscalDocument: ""
		};
	}

	static get validationSchema() {
		return Yup.object().shape({
			message: Yup.string()
				.max(
					MESSAGE_MAX_LENGTH,
					`O texto deve conter até ${MESSAGE_MAX_LENGTH} caracteres.`
				)
				.when("replyType", {
					is: 2,
					then: (schema) => schema.required("Campo obrigatório")
				}),
			visitDatetime: Yup.date()
				.min(new Date(), "Data inválida")
				.when("replyType", {
					is: 1,
					then: (schema) => schema.required("Campo obrigatório")
				}),
			visitAmount: Yup.number().when("replyType", {
				is: 1,
				then: (schema) => schema.required("Campo obrigatório")
			}),
			providerName: Yup.string().when("visitDatetime", {
				is: (date: Date) =>
					date && (date.getHours() >= 22 || date.getHours() < 6),
				then: (schema) =>
					schema.required("Campo obrigatório para visitas noturnas")
			}),
			providerFiscalDocument: Yup.string().when("visitDatetime", {
				is: (date: Date) =>
					date && (date.getHours() >= 22 || date.getHours() < 6),
				then: (schema) =>
					schema
						.required("Campo obrigatório para visitas noturnas")
						.min(14, "Deve conter 14 caracteres")
						.max(14, "Deve conter 14 caracteres")
			})
		});
	}
}
