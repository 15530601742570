import ActionButtonsSection from "../action-buttons-section";
import { BaseReplySection } from "../base-reply-section";

export default function SelectReplyTypeSection({
	onBudgetClick,
	onMessageClick,
	onRefuseClick
}: {
	onBudgetClick: () => void;
	onMessageClick: () => void;
	onRefuseClick: () => void;
}) {
	return (
		<BaseReplySection title="Selecione uma das opções">
			<ActionButtonsSection
				mainButtonType="button"
				onClickMainButton={onBudgetClick}
				onClickSecondaryButton={onMessageClick}
				onClickRefuseButton={onRefuseClick}
			/>
		</BaseReplySection>
	);
}
