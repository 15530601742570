import { IcoClose } from "assets/icons";
import ImgVideo from "assets/images/img-video.png";
import { Button, Content } from "..";

import { ModalProps } from "./types";

import styles from "./styles.module.scss";

export function ModalVideoPresentation({ open, onClose }: ModalProps) {
	return open ? (
		<div className={styles.container} onClick={onClose}>
			<Content>
				<div className={styles.header}>
					<h2 className="heading_md font-medium text-neutral-600">
						URL Vídeo Apresentação
					</h2>
					<button aria-label="Fechar Modal" onClick={onClose}>
						<IcoClose color="#773FAF" width="32" height="32" />
					</button>
				</div>
				<div className={styles.content}>
					<p className="heading_sm font-medium text-neutral-600">
						Como enviar um vídeo de apresentação
					</p>
					<img src={ImgVideo} alt="" />
					<p className="body text-neutral-600">
						1 - Grave um vídeo com o seu celular na posição horizontal sobre o
						seu trabalho: fale um pouco sobre você, seu tempo de experiência e
						mostre um dos serviços que você realiza, explique qual era o
						problema do cliente e como você resolveu.
					</p>
					<p className="body text-neutral-600">
						2 - Ao finalizar o vídeo, faça o envio para uma conta do Youtube.
						Copie o endereço do vídeo (url) e cole no campo indicado do
						formulário.
					</p>
					<p className="body text-neutral-600">
						3 - Pronto! Seu vídeo vai ficar disponível quando os clientes
						buscarem informações sobre você e o seu trabalho.
					</p>
				</div>
			</Content>
		</div>
	) : null;
}

export function ModalSomething({ open, onClose }: ModalProps) {
	return open ? (
		<div className={styles.container} onClick={onClose}>
			<Content>
				<div className={styles.header}>
					<h2 className="heading_md font-medium text-neutral-600">
						URL Vídeo Apresentação
					</h2>
					<button aria-label="Fechar Modal" onClick={onClose}>
						<IcoClose color="#773FAF" width="32" height="32" />
					</button>
				</div>
				<div className={styles.content}>
					<p className="heading_sm font-medium text-neutral-600">
						Como enviar um vídeo de apresentação
					</p>
					<img src={ImgVideo} alt="" />
					<p className="body text-neutral-600">
						1 - Grave um vídeo com o seu celular na posição horizontal sobre o
						seu trabalho: fale um pouco sobre você, seu tempo de experiência e
						mostre um dos serviços que você realiza, explique qual era o
						problema do cliente e como você resolveu.
					</p>
					<p className="body text-neutral-600">
						2 - Ao finalizar o vídeo, faça o envio para uma conta em rede social
						como o Youtube, Facebook ou Tik Tok. Copie o endereço do vídeo (url)
						e cole no campo indicado do formulário.
					</p>
					<p className="body text-neutral-600">
						3 - Pronto! Seu vídeo vai ficar disponível quando os clientes
						buscarem informações sobre você e o seu trabalho.
					</p>
				</div>
			</Content>
		</div>
	) : null;
}

export function ModalBudgetRequestRefuseConfirmation({
	open,
	onClose,
	onAgree
}: ModalProps) {
	return open ? (
		<div className={styles.container} onClick={onClose}>
			<Content>
				<div className={styles.header}>
					<h2 className="heading_md font-medium text-neutral-600">
						Tem certeza que deseja recusar o orçamento?
					</h2>
					<button aria-label="Fechar Modal" onClick={onClose}>
						<IcoClose color="#773FAF" width="32" height="32" />
					</button>
				</div>
				<div className={styles.content}>
					<p className="body text-neutral-600">
						Esta ação encerrará o contato com o cliente
					</p>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						width: "100%"
					}}
				>
					<Button variant="primary" onClick={onAgree}>
						Sim, recusar
					</Button>
					<Button variant="text" onClick={onClose}>
						Cancelar
					</Button>
				</div>
			</Content>
		</div>
	) : null;
}
