import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
	Button,
	Content,
	Input,
	Loading,
	RegistrationHeader
} from "components";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getPartnerUuid, partnerLogin } from "services/auth";
import { triggerAnalyticsEvent } from "services/firebase";
import { setFirstAcess } from "store/features/flags/slice";
import {
	resetRegistration,
	setServiceProviderUserId
} from "store/features/registration/slice";
import { setPartnerUuid, setUserId } from "store/features/tokens/slice";
import { SelfRegisterTokens } from "store/features/tokens/types";
import styles from "./styles.module.scss";

const SignInSchema = Yup.object().shape({
	email: Yup.string().required("Campo obrigatório"),
	password: Yup.string().required("Campo obrigatório")
});

export default function LoginPage() {
	const [submitting, setSubmitting] = useState<boolean>(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const formik = useFormik({
		initialValues: {
			email: "",
			password: ""
		},
		validationSchema: SignInSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			dispatch(resetRegistration());

			await partnerLogin(values.email, values.password)
				.then(async (account) => {
					if (account) {
						setSubmitting(false);
						const accountData = account as SelfRegisterTokens;
						dispatch(setServiceProviderUserId(accountData.user_id));
						dispatch(setUserId(accountData.user_id));

						await getPartnerUuid().then(async (uuid) => {
							if (uuid) {
								dispatch(setFirstAcess(uuid === ""));
								dispatch(setPartnerUuid(uuid as string));
							} else {
								dispatch(setFirstAcess(true));
								dispatch(setPartnerUuid(""));
							}

							navigate("/dashboard");
						});
					}
				})
				.catch((error) => {
					setSubmitting(false);
					toast.error("Algo saiu errado, tente novamente", {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light"
					});
				});
		}
	});

	useEffect(() => {
		triggerAnalyticsEvent("cp-open-login");
	}, []);

	return (
		<div className={styles.container}>
			<RegistrationHeader
				name="Boas vindas!"
				description="Se você já é um parceiro cadastrado, entre com seus dados."
			/>
			<form onSubmit={formik.handleSubmit}>
				<Content className={styles.registerContainer}>
					<Input
						id="email"
						name="email"
						placeholder="Digite seu e-mail aqui"
						label="E-MAIL"
						value={formik.values.email || ""}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.email && formik.errors.email}
						errorMessage={formik.errors.email}
						type="email"
					/>
					<div className="w-full text-right">
						<Input
							id="password"
							name="password"
							placeholder="Digite sua senha aqui"
							label="SENHA"
							type="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.password && formik.errors.password}
						/>
						<div className="flex justify-end">
							<Button
								className="mt-2 p-0 h-6 w-fit"
								type="button"
								variant="text"
								onClick={() => navigate("/reset-password")}
							>
								Esqueci minha senha
							</Button>
						</div>
					</div>

					<Button
						type="submit"
						disabled={!formik.isValid || !formik.dirty || submitting}
					>
						{submitting ? <Loading size="small" /> : "Entrar"}
					</Button>

					<div className="mx-auto">
						<span>Ainda não possui uma conta?</span>

						<Button
							type="button"
							variant="text"
							onClick={() => navigate("/signin")}
						>
							Cadastrar conta
						</Button>
					</div>
				</Content>
			</form>
		</div>
	);
}
